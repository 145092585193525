import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NEW_PASSWORD, NEW_ORGANIZATION_NAME } from "../../../api";
import { useForm } from "react-hook-form";
import {
  MainContainer,
  Form,
  Field,
  FormButton,
  Button,
  ButtonTalent,
  FieldBackground,
} from "../../../Styles/FormStyles.js";
import axios from "axios";
import { decode as base64_decode } from "base-64";
import logo from "../../../assets/talents_logo.png";
import {
  updateProfileData,
  getProfileData,
} from "../../../actions/actionsData";
import { useDispatch, useSelector } from "react-redux";

const ChangePass = () => {
  const [user, setUser] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const onSubmit = (data) => {
  //   setUser(data);
  //   axios
  //     .get(NEW_PASSWORD, {
  //       headers: {
  //         token,
  //         newpassword: base64_encode(data.newpass),
  //       },
  //     })
  //     .then((response) => {
  //       setResponse(response.data);
  //       console.log("PASSWORD", response.data);
  //       console.log("PASSWORD", data.newpass);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfileData());
    // console.log("FILENAME",filename);
  }, []);
  const profileData = useSelector((state) => state.profileData?.name);

  const onSubmitName = (data) => {
    setUser(data);
    // axios
    //   .get(NEW_ORGANIZATION_NAME, {
    //     headers: {
    //       token,
    //       newname: base64_encode(data.newname),
    //     },
    //   })
    //   .then((response) => {
    //     setResponse(response.data);
    // console.log("PASSWORD", response.data);
    //   console.log("PASSWORD", data.newpass);
    // })
    // .catch((error) => {
    //   console.log(error);
    // });

    dispatch(updateProfileData(data.newname));
  };
  return (
    <>
      {token ? (
        <MainContainer>
          {/* <Form onSubmit={handleSubmit(onSubmit)}>
            <h1>Set new password</h1>
            <FieldBackground>
              <Field>
                <label>New password</label>
                <input
                  placeholder="New password"
                  type="password"
                  {...register("newpass", { required: true })}
                />
              </Field>
              {errors.newpass && (
                <p className="text-error">Field cannot be empty!</p>
              )}
            
            </FieldBackground>
            <FormButton type="submit">Set New Password</FormButton>
          </Form> */}
          <Form onSubmit={handleSubmit(onSubmitName)}>
            <h1>Set new name</h1>
            <FieldBackground>
              <Field>
                <label>New name</label>
                {profileData ? (
                  <input
                    placeholder="New password"
                    type="newname"
                    {...register("newname", { required: true })}
                    defaultValue={base64_decode(profileData)}
                  />
                ) : (
                  <input
                    placeholder="New password"
                    type="newname"
                    {...register("newname", { required: true })}
                    defaultValue={profileData}
                  />
                )}
              </Field>
              {errors.newname && (
                <p className="text-error">Field cannot be empty!</p>
              )}
            </FieldBackground>
            <FormButton type="submit">Set New Name</FormButton>
          </Form>
        </MainContainer>
      ) : (
        navigate("/logIn")
      )}
    </>
  );
};

export default ChangePass;
