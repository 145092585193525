import React, { useEffect, useState } from "react";
import { PROJECT_LIST } from "../../api";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { decode as base64_decode } from "base-64";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import projectImg from "../../assets/project_image.jpg";
import { getColor } from "../../Styles/color";
import Loader from "../../Layout/Loader";

const Projects = () => {
  const [projectList, setProjectList] = useState([]);
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [loading, isLoading] = useState(true);

  useEffect(() => {
    axios
      .get(PROJECT_LIST, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        setProjectList(response.data);
        isLoading(false);
        console.log("PROJECTS", response.data);
      });
    // console.log("RRR",response);
  }, []);

  useEffect(() => {
    if (
      projectList === "\n{'warning':'token_expiration'}" ||
      projectList === "\n" ||
      projectList === null
    ) {
      navigate("/logIn");
    }
  });

  return (
    <ProjectListContainer>
      {/* <Loader loading={loading} /> */}
      {token ? (
        <>
          <div className="text">
            <h1>Your projects</h1>
            <Link to="/newProject">
              <button>+ create project</button>
            </Link>
          </div>
          {Array.isArray(projectList)
            ? projectList.map((project) => (
                <Link to={`/projects/${project.id}`}>
                  <div className="projects">
                    {project.assets?.map((ass,index) => (
                      <div  key={index}>
                      <img
                        src={`https://api.talents.wave2p0.com/assets/project/${base64_decode(
                          ass.filename
                        )}`}
                      />
                      </div>
                    ))}
                    <div className="description">
                      <p key={project?.id} className="linkname">
                        {base64_decode(project?.name)}
                      </p>
                      <p className="counter">
                        {project.matchtalents.length} matching ,{" "}
                        {project.assignedtalents.length} assigned{" "}
                      </p>
                      {project?.skills?.map((skill) => (
                        <button
                          style={{
                            backgroundColor: getColor(base64_decode(skill.skillname)),
                            padding: "5px 15px",
                            borderRadius: 15,
                            border: "none",
                            fontSize: "1em",
                            marginRight: 15,
                            marginTop: 10,
                            color:"white"
                          }}
                          key={skill.id}
                        >
                          {base64_decode(skill?.skillname)}
                        </button>
                      ))}
                    </div>
                  </div>
                </Link>
              ))
            : null}
        </>
      ) : (
        <Navigate to="/logIn" />
      )}
    </ProjectListContainer>
  );
};

const ProjectListContainer = styled.div`
  button {
    border: none;
    background: transparent;
    color: #0c5cdb;
    font-size: 20px;
  }
  h1 {
    font-size: 20px;
  }
  .counter {
    color: #4f5359;
  }
  .linkname {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 500;
  }
  .projects {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 20px;
    width: 50%;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    color: black;
    align-content: center;
    img {
      width: 8rem;
      border-radius: 10px;
    }
    .description {
      padding-left: 15px;
    }
  }
  .text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default Projects;
