import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { HeaderContainer } from "./LayoutStyle";
import { Button, FormButton } from "../Styles/FormStyles";
import logo from "../assets/talents_logo.png";
import avatar from "../assets/no_avatar.png";

import { decode as base64_decode } from "base-64";
import { ORGANIZATION_PROFILE } from "../api";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData, updateProfileData } from "../actions/actionsData";

const HeaderOrg = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");
  const [response, setResponse] = useState([]);
  const dispatch = useDispatch();
  //  const filename=useSelector((state)=>state.assests)
  // const filename=profileData.assets[0];

  // useEffect(() => {
  //   dispatch(getProfileData());

  //   // console.log("FILENAME",filename);
  // }, []);
  const profileData = useSelector((state) => state.profileData?.name);
  const filename = useSelector((state) => state.profileData?.asset);
  const defaultImageUrl = avatar

  // console.log("filenma",filename)

  function LogOut() {
    localStorage.removeItem("userToken");
    navigate("/logIn");
  }
  useEffect(() => {
    axios
      .get(ORGANIZATION_PROFILE, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        dispatch(getProfileData());
        console.log(response.assets, "ORG");
      });
   
  }, []);

  return (
    <HeaderContainer>
      <Link to="/">
        <img src={logo} />
      </Link>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {/* {response?.map((detail) => (
          <p>{detail.name}</p>
        ))}  */}
        {/* <p style={{ margin: "24px 20px" }}>{name}</p> */}
        {profileData ? (
        <>
          <p style={{ margin: "24px 10px" }}>{base64_decode(profileData)}</p>
          <img
            src={filename ? `https://api.talents.wave2p0.com/assets/organization/${base64_decode(
              filename
            )}`: defaultImageUrl}
            alt={profileData.name || "Profile"}
          />
        </>
      ) : (
        <p>No profile data</p>
      )}
        {/* {profileData.assets ? (
          <img
            src={`https://api.talents.wave2p0.com/assets/organization/${base64_decode(
              filename
            )}`}
            alt=""
          />
        ) : ( 
           <img
            src={`https://images.unsplash.com/photo-1682688759457-52bcb4dc1578?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`}
            alt=""
          /> 
        )} */}
        <FormButton onClick={LogOut} isSelected auth>
          Log Out
        </FormButton>
      </div>
    </HeaderContainer>
  );
};

export default HeaderOrg;
