import baseURL from "./base";

export const REGISTER_URL = baseURL + "/organization.php?command=register";
export const LOGIN_URL = baseURL + "/organization.php?command=login";
export const ORGANIZATION_PROFILE =
  baseURL + "/organization.php?command=profile";
export const TALENTS_LIST = baseURL + "/organization.php?command=talents";
export const CREATE_TALENT = baseURL + "/organization.php?command=createtalent";
export const PROJECT_LIST = baseURL + "/organization.php?command=projects";
export const CREATE_PROJECT =
  baseURL + "/organization.php?command=createproject";
export const SET_SKILLS =
  baseURL + "/organization.php?command=setprojectskills";
export const EDIT_NAME = baseURL + "/organization.php?command=setprojectname";
export const EDIT_DESCRIPTION =
  baseURL + "/organization.php?command=setprojectdescription";
export const SET_IMAGE_PROFILE =
  baseURL + "/organization.php?command=setprofileimage";
export const ADD_TALENT_PROJECT =
  baseURL + "/organization.php?command=addtalenttoproject";
export const REMOVE_TALENT_PROJECT =
  baseURL + "/organization.php?command=removetalentfromproject";
export const REMOVE_PROJECT =
  baseURL + "/organization.php?command=deleteproject";
export const NEW_PASSWORD =
  baseURL + "/organization.php?command=changepassword";
  export const NEW_ORGANIZATION_NAME =
  baseURL + "/organization.php?command=changename";
export const SET_PROJECT_IMAGE =
  baseURL + "/organization.php?command=setprojectimage";

export const SET_TEST= baseURL + "/organization.php?command=teststoreimage";
