import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  EDIT_NAME,
  EDIT_DESCRIPTION,
  PROJECT_LIST,
  SET_SKILLS,
  REMOVE_PROJECT,
} from "../../../api";
import {
  MainContainer,
  Form,
  Field,
  FormButton,
  Button,
  EditFormButton,
  FieldBackground,
} from "../../../Styles/FormStyles.js";
import axios from "axios";
import { encode as base64_encode, decode as base64_decode } from "base-64";
import SetProjectImage from "./SetProjectImage";

const EditProject = ({ nameValue }) => {
  const [user, setUser] = useState([]);
  const [response1, setResponse1] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");
  const { id } = useParams();
  const [projectList, setProjectList] = useState([]);
  const [loading, isLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(PROJECT_LIST, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        setProjectList(response.data);
        isLoading(false);
      });
    // console.log(projectList[0].name, "REDSSSS");
  }, []);
  const onSubmit = (data) => {
    setUser(data);
    let separatedArray = data.skills.split(/[ ,]+/);
    console.log("USERd", separatedArray);
    axios
      .all([
        axios.get(EDIT_NAME, {
          headers: {
            token: token,
            projectid: id,
            projectname: base64_encode(data.projectname),
          },
        }),
        axios.get(EDIT_DESCRIPTION, {
          headers: {
            token: token,
            projectid: id,
            projectdescription: base64_encode(data.projectdescription),
          },
        }),
        axios.get(SET_SKILLS, {
          headers: {
            token: token,
            projectid: id,
            skills: base64_encode(separatedArray),
          },
        }),
      ])
      .then((response1) => {
        setResponse1(response1.data);
        console.log("newProject", response1);
        navigate(`/projects/${id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const RemoveProject = () => {
    axios
      .get(REMOVE_PROJECT, {
        headers: {
          token,
          projectid: id,
        },
      })
      .then((response) => {
        navigate("/projects");
        console.log("DELETE", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {token ? (
        <MainContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p style={{ textAlign: "center", fontSize: 30, paddingBottom: 25 }}>
              Edit Project
            </p>
            <FieldBackground>
              {projectList
                .filter((project) => project?.id == id)
                .map((project) => (
                  <>
                    <Field>
                      <label>New Project Name</label>
                      <input
                        type="text"
                        {...register("projectname", { required: true })}
                        defaultValue={base64_decode(project?.name)}
                      />
                    </Field>

                    {errors.projectname && (
                      <p className="text-error">Field cannot be empty!</p>
                    )}
                    <Field>
                      <label>New Project Description</label>
                      <input
                        type="text"
                        {...register("projectdescription", { required: true })}
                        defaultValue={base64_decode(project?.description)}
                      />
                    </Field>
                    {errors.projectname && (
                      <p className="text-error">Field cannot be empty!</p>
                    )}
                    {project?.skills?.map((skill) => (
                      <Field>
                        <label>Set skills</label>
                        <input
                          type="text"
                          {...register("skills", { required: true })}
                          defaultValue={base64_decode(skill?.skillname)}
                        />
                      </Field>
                    ))}
                    {errors.skills && (
                      <p className="text-error">Field cannot be empty!</p>
                    )}
                  </>
                ))}

              <SetProjectImage />
              <div className="buttonContainer">
                <EditFormButton type="submit">Edit Project</EditFormButton>
                <EditFormButton
                  delete
                  onClick={() => {
                    RemoveProject();
                  }}
                  className="remove"
                  style={{ paddingLeft: 20 }}
                >
                  Delete Project
                </EditFormButton>
              </div>
            </FieldBackground>
          </Form>
          <Link to={`/projects/${id}`} style={{ paddingTop: 30 }}>
            <span style={{ color: "#647295", cursor: "pointer" }}>go back</span>
          </Link>
        </MainContainer>
      ) : (
        navigate("/logIn")
      )}
    </>
  );
};

export default EditProject;
