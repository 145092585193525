import React, { useEffect, useState } from "react";
import {
  NEW_PASSWORD,
  PROJECT_LIST,
  ADD_TALENT_PROJECT,
  REMOVE_TALENT_PROJECT,
  REMOVE_PROJECT,
} from "../../api";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { decode as base64_decode } from "base-64";
import { TalentContainer, Container } from "../../Styles/Global";
import profileImage from "../../assets/derek-mack-CcRZ4k3c6gA-unsplash.jpg";
import plus from "../../assets/icons8-plus-+-50.png";
import styled from "styled-components";
import { getColor } from "../../Styles/color";
import { useNavigate } from "react-router-dom";
import projectImg from "../../assets/project_image.jpg";
import Loader from "../../Layout/Loader";
import user from "../../assets/user.png";

function ProjectDetail() {
  const [projectList, setProjectList] = useState([]);
  const token = localStorage.getItem("userToken");
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, isLoading] = useState(true);
  let [product, setProduct] = useState(projectList);

  useEffect(() => {
    axios
      .get(PROJECT_LIST, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        setProjectList(response.data);
        isLoading(false);
      });
  }, []);
  useEffect(() => {
    if (
      projectList === "\n{'warning':'token_expiration'}" ||
      projectList === "\n" ||
      projectList === null
    ) {
      navigate("/logIn");
    }
  });
  const AddTalent = (talentId) => {
    axios
      .get(ADD_TALENT_PROJECT, {
        headers: {
          token,
          projectid: id,
          talentid: talentId,
        },
      })
      .then((response) => {
        console.log("ADDD", response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const RemoveTalent = (talentId) => {
    axios
      .get(REMOVE_TALENT_PROJECT, {
        headers: {
          token,
          projectid: id,
          talentid: talentId,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeItem = (id) => {
    setProjectList(
      projectList.filter((project) =>
        project.assignedtalents.some(
          (assignedtalent) => assignedtalent.id !== id
        )
      )
    );

    console.log(projectList, "M");
  };

  return (
    <ProjectDetailContainer>
      <Link to="/projects">
        <p>Back</p>
      </Link>
      {projectList
        .filter((project) => project.id == id)
        .map((project) => {
          return (
            <>
              <Loader loading={loading} />
              <div className="projects">
                {project.assets?.map((ass) => (
                  <img
                    src={`https://api.talents.wave2p0.com/assets/project/${base64_decode(
                      ass.filename
                    )}`}
                  />
                ))}

                <div className="description">
                  <p key={project?.id} className="linkname">
                    {base64_decode(project?.name)}
                  </p>
                  <p className="counter">
                    {project.matchtalents.length} matching ,{" "}
                    {project.assignedtalents.length} assigned{" "}
                  </p>
                  <p>{base64_decode(project.description)}</p>
                  {project?.skills?.map((skill) => (
                    <button
                      style={{
                        backgroundColor: getColor(
                          base64_decode(skill.skillname)
                        ),
                        padding: "5px 15px",
                        borderRadius: 15,
                        border: "none",
                        fontSize: "1em",
                        marginRight: 15,
                        marginTop: 10,
                      }}
                      key={skill.id}
                    >
                      {base64_decode(skill?.skillname)}
                    </button>
                  ))}
                </div>
              </div>

              <Link to={`/setNewName/${id}`}>
                <button className="remove">Edit Project</button>
              </Link>

              <h2>Assigned Talents</h2>
              <Container>
                {Object.keys(project.assignedtalents).length !== 0 ? (
                  project?.assignedtalents?.map((assignedtalent) => (
                    <TalentContainer>
                      {assignedtalent.assets[0] ? (
                        assignedtalent.assets?.map((ass) => (
                          <img
                            src={`https://api.talents.wave2p0.com/assets/talent/${base64_decode(
                              ass.filename
                            )}`}
                            alt=""
                          />
                        ))
                      ) : (
                        <img src={user} />
                      )}{" "}
                      <div className="talentText">
                        <p key={assignedtalent?.id}>
                          {base64_decode(assignedtalent.name)}
                        </p>
                        <p>{base64_decode(assignedtalent.email)}</p>

                        {assignedtalent?.skills?.map((skill) => (
                          <button
                            style={{
                              backgroundColor: getColor(
                                base64_decode(skill.skillname)
                              ),
                              padding: "5px 15px",
                              borderRadius: 15,
                              border: "none",
                              fontSize: "1em",
                              marginRight: 10,
                              marginBottom: 10,
                            }}
                            key={skill.id}
                          >
                            {base64_decode(skill?.skillname)}
                          </button>
                        ))}
                      </div>
                      <button
                        onClick={() => RemoveTalent(assignedtalent.id)}
                        style={{ color: "black" }}
                        className="removeTalent"
                      >
                        Remove
                      </button>
                    </TalentContainer>
                  ))
                ) : (
                  <p>You have no talents assigned yet.</p>
                )}
              </Container>
              <h2>Matching Talents</h2>
              <Container>
                {Object.keys(project.matchtalents).length !== 0 ? (
                  project?.matchtalents?.map((matchtalent) => (
                    <div>
                      <TalentContainer>
                        {matchtalent.assets[0] ? (
                          matchtalent.assets?.map((ass) => (
                            <img
                              src={`https://api.talents.wave2p0.com/assets/talent/${base64_decode(
                                ass.filename
                              )}`}
                              alt=""
                            />
                          ))
                        ) : (
                          <img src={user} />
                        )}{" "}
                        <div className="talentText">
                          <p key={matchtalent?.id}>
                            {base64_decode(matchtalent.name)}
                          </p>
                          <p style={{ marginBottom: 10 }}>
                            {base64_decode(matchtalent.email)}
                          </p>
                          {matchtalent?.skills?.map((skill) => (
                            <button
                              style={{
                                backgroundColor: getColor(
                                  base64_decode(skill.skillname)
                                ),
                                padding: "5px 15px",
                                borderRadius: 15,
                                border: "none",
                                fontSize: "1em",
                                marginRight: 10,
                                color: "white",
                              }}
                              key={skill.id}
                            >
                              {base64_decode(skill?.skillname)}
                            </button>
                          ))}
                        </div>
                        <button
                          onClick={() => {
                            AddTalent(matchtalent.id);
                          }}
                          className="assignTalent"
                        >
                          Assign to project
                        </button>
                      </TalentContainer>
                    </div>
                  ))
                ) : (
                  <p>You don't have any talents matched.</p>
                )}
              </Container>
            </>
          );
        })}
    </ProjectDetailContainer>
  );
}

const ProjectDetailContainer = styled.div`
  .title {
    padding: 5px 15px 3px 15px;
    border: none;
    border-radius: 14px;
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    float: right;
    margin-top: 16px;
    bottom: 20px;
    cursor: pointer;
    position: relative;
    img {
      width: 1.5rem !important;
      height: auto;
      /* margin-top:3px; */
    }
  }
  .counter {
    color: #4f5359;
  }
  .remove {
    color: red;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 15px;
    float: right;
  }
  h1,
  h2 {
    margin-top: 40px;
  }
  button {
    border: none;
    background: transparent;
    color: white;
    font-size: 20px;
  }
  h1 {
    font-size: 20px;
  }
  .linkname {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 500;
  }
  .projects {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 20px;
    width: 50%;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    color: black;
    align-content: center;
    img {
      width: 8rem;
      border-radius: 10px;
    }
    .description {
      padding-left: 15px;
    }
  }
  .text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default ProjectDetail;
