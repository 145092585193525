import http from "../api/htttp_common";
import authHeader from "./AuthHeader";
import { encode as base64_encode } from "base-64";

const token = localStorage.getItem("userToken");

const getAll = () => {
  // console.log(
  //   http.get("/organization.php?command=profile")
  // );

  return http.get("/organization.php?command=profile", {
    headers: authHeader(),
  });
};

const updateData = (user) => {
console.log("FINISHED",user)
  return http.get("/organization.php?command=changename", {
    headers:{
      token,
      newname:user,
    }
  });
};

const GetDataSevice = {
  getAll,
  updateData,
};
export default GetDataSevice;
