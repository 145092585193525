import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MainContainer, Form, Field, FormButton, FieldBackground } from "../../Styles/FormStyles";
import talents_logo from "../../assets/talents_logo.png";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import { CREATE_TALENT } from "../../api";
import { useNavigate } from "react-router-dom";


const CreateTalentForm = () => {
  const [user, setUser] = useState("");
  const token = localStorage.getItem("userToken");

  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //  const dispatch = useDispatch();
 
  const onSubmit = (data) => {
    setUser(data);
    axios
      .get(CREATE_TALENT, {
        headers: {
          token: token,
          name: base64_encode(data.talentName),
          talentemail: base64_encode(data.talentemail),
        },
      })
      .then((response) => {
        setResponse(response.data);
        console.log("Console", response.data);
        alert("You have created the talent!");
        localStorage.setItem("talentsCode", response.data.invitecode);
        console.log("Name", data.talentName);

        //   dispatch( {
        //     type: GET_INVITE_CODE,
        //     payload: response.data
        // })
        navigate("/profilePage");
      });
  };
  return (
    <>
      {token ? (
        <MainContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h1>Create talent</h1>
            <FieldBackground>
            <Field>
              <label>Talent’s name</label>
              <input
                placeholder="Talent’s name"
                type="text"
                {...register("talentName", { required: true, maxLength: 20 })}
              />
            </Field>
            {errors.talentName && (
              <p className="text-error">Please check the Organization’s name</p>
            )}
            <Field>
              <label>Email address</label>
              <input
                placeholder="Talent's email"
                type="email"
                {...register("talentemail", {
                  required: true,
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
            </Field>
            </FieldBackground>
            {errors.talentemail && (
              <p className="text-error">Please check the Email</p>
            )}
            <FormButton type="submit">Create Talent</FormButton>
          </Form>
        </MainContainer>
      ) : (
        navigate("/logIn")
      )}
    </>
  );
};
export default CreateTalentForm;
