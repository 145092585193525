import { color } from "@mui/system";
import { decode as base64_decode } from "base-64";
import { encode as base64_encode } from "base-64";
export const getColor = (skillname) => {
  // if (skillname === base64_encode("css")) return "#CDF5F6";
  // if (skillname === base64_encode("jira")) return "#EFF9DA";
  // if (skillname === base64_encode("c++")) return "#F9EBDF";
  // if (skillname === base64_encode("html")) return "#F9D8D6";
  // return "#D6CDEA";

  var hash = 0;
  for (var i = 0; i < skillname.length; i++) {
    hash = skillname.charCodeAt(i) + ((hash << 5) - hash);
  }
  var color = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 5)) & 0xFF;
    color += ("00" + value.toString(16)).substr(-2);
  }
console.log("COLoR",color)
  return color;
};
