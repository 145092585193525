import React, { useEffect, useState } from "react";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import {SET_PROJECT_IMAGE, SET_TEST } from "../../../api";
import { useForm } from "react-hook-form";
import { useNavigate,useParams } from "react-router-dom";

const SetProjectImage = () => {
  // const [selectedFile, setSelectedFile] = React.useState();
  const token = localStorage.getItem("userToken");
  const {id}=useParams();
  const [selectedFile, setSelectedFile] = useState(null);
//   useEffect(()=>{
//   console.log("IDD",id)
//   },[])
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0], "H");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData();
    console.log(selectedFile, "FILE");
   
    form.append("selected", selectedFile);
   
  
    const response = await axios({
      method: "post",
      url: SET_PROJECT_IMAGE,
      data: form,
      
      headers: {
        "Content-Type": "multipart/form-data",
        token,
        filename: "image.jpg",
        projectid:id,
      },
    });
    console.log(response.data);
  };

  return (
    <form style={{paddingTop:20}}>
      <input type="file" onChange={handleFileSelect} />
      <button onClick={handleSubmit}>Upload</button>
    </form>
  );
};

export default SetProjectImage;
