import styled from "styled-components";

const handleColorType = (color) => {
  switch (color) {
    case "css":
      return "#CDF5F6";
    case "jira":
      return "#EFF9DA";
    case "c++":
      return "#F9EBDF";
    case "html":
      return "#F9D8D6";
    default:
      return "#D6CDEA";
  }
};

const TalentContainer = styled.div`
  /* padding: 20px; */
  /* margin: 15px 25px 15px 0; */
  font-size: 1em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  width: 250px;
  /* height:${(props) => (props.smaller ? "260px" : "360px")}; */
  border-radius: 15px;
  min-height:380px;

  margin:0 10px;
  button{
    margin-top:10px;
  }
  img {
    width: 15.7rem;
    height: 16rem;
    border-radius: 10px 10px 0 0;
    object-fit:cover;
    /* border: 4px solid #9057cd; */
  }
  .talentText {
    padding:10px 7px 25px 7px;

    .name {
      font-size: 1.3em;
      font-weight:400;
    }
  }
  p {
    padding: 2px 0;
  }
  .email {
    width: 220px;
    // font-size:1.1em;
    padding-bottom: 5px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .more {
    color: #0c5cdb;
    float: right;
    cursor: pointer;
  }
  .removeTalent{
    top:0;
    float:right;
    right:0;
    margin-right:10px;
    background-color:white;
    padding:5px;
    border-radius:5px;
    margin-left:10px;
    position:absolute;
    cursor:pointer;
    font-size:15px;
    color:red!important;
    
  }
  .assignTalent{
    top:0;
    float:right;
    right:0;
    margin-right:10px;
    background-color:white;
    padding:5px;
    border-radius:5px;
    margin-left:10px;
    position:absolute;
    cursor:pointer;
    font-size:15px;
    color:#4589F0;
    
  }
  position:relative;
`;

const Container = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  padding: 20px 10px 20px 0px;
  max-width:1200px;
`;
export { handleColorType, TalentContainer, Container };
