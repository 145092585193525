import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CREATE_PROJECT } from "../../api";
import { useForm } from "react-hook-form";
import {
  MainContainer,
  Form,
  Field,
  FormButton,
  Button,
  ButtonTalent,
  FieldBackground,
} from "../../Styles/FormStyles.js";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import logo from "../../assets/talents_logo.png";

const NewProjectForm = () => {
  const [user, setUser] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setUser(data);
    axios
      .get(CREATE_PROJECT, {
        headers: {
          token,
          projectname: base64_encode(data.name),
          projectdescription:base64_encode(data.description)
        },
      })
      .then((response) => {
        setResponse(response.data);
        console.log("newProject", response.data);
        navigate("/projects");
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("ENCODE", base64_encode(logo));
  };

  return (
    <>
      {token ? (
        <MainContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h1>Create new project</h1>
            <FieldBackground>
              <Field>
                <label>Project’s name</label>
                <input
                  placeholder="Project’s name"
                  type="text"
                  {...register("name", { required: true })}
                />
              </Field>
              {errors.name && (
                <p className="text-error">Field cannot be empty!</p>
              )}
               <Field>
                <label>Project’s Description</label>
                <input
                  placeholder="Project’s description"
                  type="text"
                  {...register("description",{ required: true })}
                />
              </Field>
              {errors.description && (
                <p className="text-error">Field cannot be empty!</p>
              )}
              <Link to="/projects">
                <span style={{ color: "#647295", cursor: "pointer" }}>
                  go back
                </span>
              </Link>
            </FieldBackground>
            <FormButton type="submit">Create project</FormButton>
          </Form>
        </MainContainer>
      ) : (
        navigate("/logIn")
      )}
    </>
  );
};

export default NewProjectForm;
