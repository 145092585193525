import styled from "styled-components";

export const HeaderContainer = styled.div`
  background-color: white;
  padding: 0 20px;
  font-size: 1.2em;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: black;
  box-shadow: rgba(50, 50, 93, 0) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
  /* font-size:1em */

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 20px;
  }
  li {
    padding-left: 10px;
  }
  img {
    width: 4rem;
    height:4rem;
    border-radius:50px;
    margin:0 20px 0 10px;
    object-fit:center;
  
    
  }
`;

export const MainButton = styled.div`
  padding: 10px;
  border: none;
  border-radius: 14px;
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
  width: 180px;
  text-align: center;
  color: black;
  font-weight: 700;
  margin-right:20px;
`;
