import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { useEffect } from "react";

const Sidebar = (props) => {
  const [styleS, setStyle] = useState(false);

  const Change = () => {
    setStyle(true);
  };

  return (
    <SidebarContainer>
      <NavLink
        to="/profilePage"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
      >
        <SideBarButton>Talents</SideBarButton>
      </NavLink>
      <NavLink
        to="/projects"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
      >
        <SideBarButton>My projects</SideBarButton>
      </NavLink>
      <NavLink
        to="/settings"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
      >
        <SideBarButton>Settings</SideBarButton>
      </NavLink>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  padding: 22px 10px;
  width: 12vw;
  margin: 0;
  border-radius: 5px;
  background: rgba(196, 196, 196, 0.39);
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  text-align: center;
  .active {
    button {
      background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
      padding: 10px 40px;
      color: white;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 17vw;
  }
`;

const SideBarButton = styled.button`
  padding: 10px;
  border-radius: 15px;
  border: none;
  margin: 10px 0;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
`;
// export const StyledLink = styled(NavLink)`
// background:black;

//   &.${props => props.activeClassName} {
//     background: linear-gradient(
//       180deg,
//       rgba(41, 242, 182, 0.8) 0%,
//       rgba(8, 90, 250, 0.384) 100%
//     );
//   }
// `;
export default Sidebar;
