import React, { useState } from "react";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import { SET_TALENTS_IMAGE } from "../../../api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  MainContainer,
  Form,
  Field,
  FormButton,
} from "../../../Styles/FormStyles";
import { Link } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const SetTalentsImage = () => {
  const token = localStorage.getItem("talentToken");

  
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0], "H");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData();
    console.log(selectedFile, "FILE");

    form.append("selected", selectedFile);
   
   

    const response = await axios({
      method: "post",
      url: SET_TALENTS_IMAGE,
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        token,
        filename: "image.jpg",
      },
    });
    window.location.reload();
    console.log(response);
  };

  return (
    <form>
      <h1>React File Upload</h1>
      <input type="file" onChange={handleFileSelect} />
      <button onClick={handleSubmit}>Upload</button>
    </form>
  );
};

export default SetTalentsImage;
