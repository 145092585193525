import React, { useEffect, useState } from "react";
import "./App.css";
import LogIn from "./pages/Authorization/Organization/LogIn";
import SignUp from "./pages/Authorization/Organization/SignUp";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Profile from "./pages/OrganizationProfilePage/OrganizationProfile";
import CreateTalentForm from "./pages/OrganizationProfilePage/CreateTalentForm";
import Register from "./pages/Authorization/Talent/Register";
import LogInTalents from "./pages/Authorization/Talent/LogInTalents";
import TalentsProfile from "./pages/TalentsProfile/TalentsProfile";
import Projects from "./pages/OrganizationProfilePage/Projects";
import NewProjectForm from "./pages/OrganizationProfilePage/NewProjectForm";
import SetTalentsSkills from "./pages/TalentsProfile/SetTalentsSkills";
import ImageUploader from "./pages/OrganizationProfilePage/ImageUploader";
import SetTalentsImage from "./pages/TalentsProfile/SettingsTalent/SetTalentsImage";
import { Layout, DefaultLayout, TalentsLayout } from "./Layout";
import AddTalentToProject from "./pages/OrganizationProfilePage/AddTalentToProject";
import RemoveTalentFromProject from "./pages/OrganizationProfilePage/RemoveTalentFromProject";
import ChangePass from "./pages/OrganizationProfilePage/SettingsPage/ChangePass";
import ProjectDetail from "./pages/OrganizationProfilePage/ProjectDetail";
import TalentsSettings from './pages/TalentsProfile/SettingsTalent/TalentsSettings';
import Settings from "./pages/TalentsProfile/SettingsTalent/Settings";
import SettingsOrg from "./pages/OrganizationProfilePage/SettingsPage/SettingsOrg";
import SetProfileImage from "./pages/OrganizationProfilePage/SettingsPage/SetProfileImage";
import SetProjectImage from "./pages/OrganizationProfilePage/Projects/SetProjectImage";
import EditProject from "./pages/OrganizationProfilePage/Projects/EditProject";


export const Routes = () => {

  let element = useRoutes([
    {
      element: <DefaultLayout />,
      children: [
        { path: "/logIn", element: <LogIn /> },
        { path: "/register", element: <SignUp /> },
        { path: "/registerTalent", element: <Register /> },
        { path: "/logInTalent", element: <LogInTalents /> },
        { path: "/", element: <LogIn /> },

        
      ],
    },
    {
      element: <Layout />,
      children: [
        // organiztion
        { path: "/profilePage", element: <Profile /> },
        // { path: "/", element: <Profile /> },
        // { path: "/", element: < LogIn/> },
        { path: "/newProject", element: <NewProjectForm/> },
        { path: "/addTalentToProject", element: <AddTalentToProject/> },
        { path: "/removeTalentFromProject", element: <RemoveTalentFromProject/> },
        { path: "/createTalent", element: <CreateTalentForm/> },
        { path: "/projects", element: <Projects/> },
        { path: "/setNewName/:id", element: <EditProject/> },
        { path: "settings/setImage", element: <ImageUploader/> },
        // { path: "/talents", element:   <Profile/>},
        { path: "settings/changePassOrg", element:   <ChangePass/>},
        { path: "/settings", element:   <SettingsOrg/>},
        { path: "/projects/:id", element:   <ProjectDetail/>},
        // { path: "/setProjectImage/:id", element:   <SetProjectImage/>},

        //talent
        // { path: "/profileTalentPage", element: <TalentsProfile/> },
        // { path: "/setTalentsSkills", element: <SetTalentsSkills/> },
        // { path: "/setTalentsImage", element: <SetTalentsImage/> }

      ],
    },
    {
      element: <TalentsLayout />,
      children: [
        { path: "/profileTalentPage", element: <TalentsProfile/> },
        // { path: "/", element: <TalentsProfile/> },
        { path: "/setTalentsSkills", element: <SetTalentsSkills/> },
        { path: "/setTalentsImage", element: <SetTalentsImage/> },
        { path: "/talentsPassword", element: <TalentsSettings/> },
        { path: "/talentsSettings", element: <Settings/> },

      ],
    },
  ]);

  return element;
};
