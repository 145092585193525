import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {SET_TALENTS_SKILLS } from "../../api";
import { useForm } from "react-hook-form";
import {
  MainContainer,
  Form,
  Field,
  FormButton,
  Button,
  ButtonTalent,
} from "../../Styles/FormStyles.js";
import axios from "axios";
import { encode as base64_encode } from "base-64";



const SetTalentsSkills = () => {
  const [user, setUser] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("talentToken");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    setUser(data.toLowerCase );
  
    let separatedArray = data.skills.split(/[ ,]+/);
   
    console.log("USERd",base64_encode(separatedArray));
    axios
      .get(SET_TALENTS_SKILLS, {
        headers: {
           token,
          skills: base64_encode(separatedArray),
        },
      })
      .then((response) => {
        setResponse(response.data);
      
        console.log("setTalent", response.data);
        navigate("/profileTalentPage")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  const Check =()=>{
  //    console.log()
  //  }
  return (
    <>
      {token ? (
        <MainContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p style={{ textAlign: "center", fontSize: 30 }}>
              Set your  skills
            </p>
             <Field>
              <label>Separate skills with spacebar!Ex: HTML:5</label>
              <input
                placeholder="skills"
                type="text"
                // onChange={Check()}
                {...register("skills", { required: true })}
              />
            </Field>
            {errors.skills && (
              <p className="text-error">Field cannot be empty!</p>
            )}
            <FormButton type="submit">Set skills</FormButton>

            <Link to="/profileTalentPage">
              <span style={{ color: "#647295", cursor: "pointer" }}>
                go back
              </span>
            </Link>
          </Form>
        </MainContainer>
      ) : (
        navigate("/logIn")
      )}
    </>
  );
};

export default SetTalentsSkills;
