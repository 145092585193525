import { GET_PROFILE_DATA, UPDATE_PROFILE_DATA } from "../actions/types.js";
const initialState = [];

export const profileReducer = (profileData = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROFILE_DATA:
      return payload;
    case UPDATE_PROFILE_DATA:
      return payload;
    default:
      return profileData;
  }
};
export const updatedProfileReducer = (profileData = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PROFILE_DATA:
      return payload;
    default:
      return profileData;
  }
};
