import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NEW_PASSWORD_TALENTS, NEW_ORGANIZATION_NAME } from "../../../api";
import { useForm } from "react-hook-form";
import {
  MainContainer,
  Form,
  Field,
  FormButton,
  Button,
  ButtonTalent,
  FieldBackground,
} from "../../../Styles/FormStyles.js";
import axios from "axios";
import { encode as base64_encode } from "base-64";

const TalentsSettings = () => {
  const [user, setUser] = useState("");
  const [response, setResponse] = useState("");
 
  const navigate = useNavigate();
  const token = localStorage.getItem("talentToken");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setUser(data);
    axios
      .get(NEW_PASSWORD_TALENTS, {
        headers: {
          token,
          newpassword: base64_encode(data.newpass),
        },
      })
      .then((response) => {
        setResponse(response.data);
        console.log("PASSWORD", response.data);
        console.log("PASSWORD", data.newpass);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const onSubmitName = (data) => {
  //   setUser(data);
  //   axios
  //     .get(NEW_ORGANIZATION_NAME, {
  //       headers: {
  //         token,
  //         newname: base64_encode(data.newname),
  //       },
  //     })
  //     .then((response) => {
  //       setResponse(response.data);
  //       console.log("PASSWORD", response.data);
  //       console.log("PASSWORD", data.newpass);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

 
  return (
    <>
      {token ? (
        <MainContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h1>Set new password</h1>
            <FieldBackground>
              <Field>
                <label>New password</label>
                <input
                  placeholder="New password"
                  type="password"
                  {...register("newpass", { required: true })}
                />
              </Field>
              {errors.newpass && (
                <p className="text-error">Field cannot be empty!</p>
              )}
            </FieldBackground>
            <FormButton type="submit">Set New Password</FormButton>
          </Form>
          {/* <Form onSubmit={handleSubmit(onSubmitName)}>
            <h1>Set new name</h1>
            <FieldBackground>
              <Field>
                <label>New name</label>
                <input
                  placeholder="New password"
                  type="newname"
                  {...register("newname", { required: true })}
                />
              </Field>
              {errors.newpass && (
                <p className="text-error">Field cannot be empty!</p>
              )}
            </FieldBackground>
            <FormButton type="submit">Set New Name</FormButton>
          </Form> */}
        </MainContainer>
      ) : (
        navigate("/logIn")
      )}
    </>
  );
};

export default TalentsSettings;
