import React, { useState, useEffect } from "react";
// import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { MainContainer, Form, Field, FormButton, FieldBackground } from "../../Styles/FormStyles";
import talents_logo from "../../assets/talents_logo.png";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import { ADD_TALENT_PROJECT, REMOVE_TALENT_PROJECT } from "../../api";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { GET_INVITE_CODE } from "../../type";

const RemoveTalentFromProject = () => {
  const [user, setUser] = useState("");
  const token = localStorage.getItem("userToken");

  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //  const dispatch = useDispatch();
 
  const onSubmit = (data) => {
    setUser(data);
    axios
      .get(REMOVE_TALENT_PROJECT, {
        headers: {
           token,
          projectid: data.projectid,
          talentid: data.talentid
        },
      })
      .then((response) => {
        setResponse(response.data);
        console.log("Assigned", response.data);
        alert("Talent has been removed!");
      

        //   dispatch( {
        //     type: GET_INVITE_CODE,
        //     payload: response.data
        // })
        navigate("/profilePage");
      });
  };
  return (
    <>
      {token ? (
        <MainContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <img src={talents_logo} />
            <h1>Remove Talent from project</h1>
            <FieldBackground>
            <Field>
              <label>Project’s id</label>
              <input
                placeholder="Project’s id"
                type="text"
                {...register("projectid", { required: true, maxLength: 10 })}
              />
            </Field>
            {errors.projectid && (
              <p className="text-error">Please write project's id</p>
            )}
            <Field>
              <label>Talent's id</label>
              <input
                placeholder="Talent's id"
                type="text"
                {...register("talentid", {
                  required: true,
                })}
              />
            </Field>
            {errors.talentid && (
              <p className="text-error">Please write talent's id</p>
            )}
            </FieldBackground>
            <FormButton type="submit">Remove talent from project</FormButton>
          </Form>
        </MainContainer>
      ) : (
        navigate("/logIn")
      )}
    </>
  );
};
export default RemoveTalentFromProject;
