import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { MainButton } from "../../../Layout/LayoutStyle";

const SettingsOrg = () => {
  return (
    <SettingsContainer>
      <NavLink to="/settings/changePassOrg">
        <MainButton>Change password</MainButton>
      </NavLink>
      <NavLink to="/settings/setImage">
      <MainButton>Set image</MainButton>
      </NavLink>
    </SettingsContainer>
  );
};

const SettingsContainer=styled.div`
display:flex;
flex-direction:row;

`

export default SettingsOrg;
