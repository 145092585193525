import React, { useState } from "react";
// import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { MainContainer, Form, FieldMain, FormButton,Button,ButtonTalent } from "../../../Styles/FormStyles";
import talents_logo from "../../../assets/talents_logo.png";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import { REGISTER_TALENT } from "../../../api";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Register = () => {
  const [user, setUser] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const ChangeForm = () => {
    navigate("/register");
  };
  const onSubmit = (data) => {
    setUser(data);
    axios
      .get(REGISTER_TALENT, {
        headers: {
          verifyemail: base64_encode(data.email),
          invitecode: base64_encode(data.invitecode),
          newpassword: base64_encode(data.password),
        },
      })
      .then((response) => {
        setResponse(response.data);
        navigate("/ProfileTalentPage");
        console.log(response.data);
      });
  };
  return (
    <MainContainer main>
      <Form  talents onSubmit={handleSubmit(onSubmit)}>
      <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            top: -40,
            right: 0,
            zIndex: -3,
          }}
        >
          <Button onClick={ChangeForm}>Organization</Button>
          <ButtonTalent talents >Talent</ButtonTalent>
        </div>
        <img src={talents_logo} />
        <h1>Talents</h1>
        <FieldMain>
          <label>Email address</label>
          <input
            placeholder="Verify email"
            type="email"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </FieldMain>
        {errors.email && <p className="text-error">Please check the Email</p>}
        <FieldMain>
          <label>Your invite code</label>
          <input
            placeholder="Invite code"
            type="password"
            {...register("invitecode", {
              required: true,
              // pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
            })}
          />
        </FieldMain>
        {errors.invitecode && (
          <p className="text-error">Please paste yout invite code here</p>
        )}
        <FieldMain>
          <label>Password</label>
          <input
            placeholder=" New Password"
            type="password"
            {...register("password", {
              required: true,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
            })}
          />
        </FieldMain>
        {errors.password && (
          <p className="text-error">Please check the Password</p>
        )}
        <FieldMain>
          <label>
            <input
              type="checkbox"
              {...register("terms", { required: false })}
              style={{ marginRight: 10, marginTop: 5 }}
            />
            Keep me logged in
          </label>
        </FieldMain>
        <FormButton auth type="submit">Register talent's account</FormButton>
        <p style={{ fontSize: 14, textAlign: "center", paddingBottom: 20 }}>
          Already have an account?{" "}
          <Link to="/logInTalent">
            <span style={{ color: "#647295", cursor: "pointer" }}>
             Log in here
            </span>
          </Link>
        </p>
        <p style={{ fontSize: 14, textAlign: "center", paddingBottom: 20 }}>
          Forgot password?
          <span style={{ color: "#647295", cursor: "pointer" }}>
            {" "}
            Click here to reset.
          </span>
        </p>
      </Form>
    </MainContainer>
  );
};
export default Register;
