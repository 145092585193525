import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { HeaderContainer } from "./LayoutStyle";
import { Button, FormButton } from "../Styles/FormStyles";
import logo from "../assets/talents_logo.png";
import { decode as base64_decode } from "base-64";

const Header = () => {
  const navigate = useNavigate();

  function LogOut() {
    localStorage.removeItem("talentToken");
    navigate("/logIn");
  }
  // const name = localStorage.getItem("talentName");

  return (
    <HeaderContainer>
      <Link to="/">
      <img src={logo} />
      </Link>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <p style={{ margin: "24px 20px" }}>{base64_decode(name)}</p> */}
        <FormButton onClick={LogOut} isSelected auth>
          Log Out
        </FormButton>
      </div>
    </HeaderContainer>
  );
};

export default Header;
