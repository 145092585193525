import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { MainButton } from "../../../Layout/LayoutStyle";

const Settings = () => {
  return (
    <SettingsContainer>
      <NavLink to="/changePassOrg">
        <MainButton>Change password</MainButton>
      </NavLink>
      <NavLink to="/setTalentsImage">
        <MainButton>Set image</MainButton>
      </NavLink>
      <Link to="/setTalentsSkills">
        <MainButton>Set my skills</MainButton>
      </Link>
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default Settings;
