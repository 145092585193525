import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

const SidebarTalents = () => {
  //   const [color, setColor] = useState(true);
  //   const handleChange = () => {
  //     setColor(!color);
  //   };

  return (
    <SidebarContainer>
      <NavLink to="/profileTalentPage">
        <SideBarButton  className={({ isActive }) => (isActive ? "active" : "inactive")}>Home</SideBarButton>
      </NavLink>
      <NavLink to="/talentsSettings">
        <SideBarButton  className={({ isActive }) => (isActive ? "active" : "inactive")}>Settings</SideBarButton>
      </NavLink>
    </SidebarContainer>
  );
};
const SidebarContainer = styled.div`
  padding: 22px 10px;
  width: 12vw;
  margin: 0;
  border-radius: 5px;
  background: rgba(196, 196, 196, 0.39);
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  text-align: center;
  .active {
    button {
      background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
      padding: 10px 40px;
      color: white;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 17vw;
  }
`;

const SideBarButton = styled.button`
  padding: 10px;
  border-radius: 15px;
  border: none;
  margin: 10px 0;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  background:transparent;
  transition: 0.3s;
  &:focus {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    padding: 10px 40px;
    color: white;
    cursor: pointer;
  }
`;


export default SidebarTalents;
