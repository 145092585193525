import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: ${(props) => (props.main ? `100vh` : `50vh`)};
  align-self:center;
`;
export const Form = styled.form`
  /* background-color: #CDD1F1; */
  background: ${(props) => (props.talents ? "#CDD1F1" : "white")};
  border-radius: ${(props) => (props.radius ? "0 0 20px 20px" : "20px")};
  margin:0 auto;
  /* padding: 30px 40px 20px 40px; */
  /* position: absolute; */
  width: 40%;
  z-index: 999;
  img {
    width: 5rem;
  }
  h1 {
    text-align: center;
    font-size: 1.8em;
    padding-bottom: 20px;
  }
  .buttonContainer{
    display:flex;
    justify-content:space-between;
    width:100%;
  }
  .contentLogin{
    padding:0 20px;
  }
`;

export const FieldMain = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;
  input {
    padding: 0.5em 0 0.7em 0.5em;
    border-radius: 15px;
    border: 2px solid #b2b1b1;
  }
  label {
    margin-left: 0.5em;
  }
`;
export const Field = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;
  input {
    padding: 1em 0 1.3em 0.5em;
    box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 4px;
    border-radius: 10px;
    border: none;
  }
  label {
    margin-left: 0.5em;
  }
`;
export const FieldBackground = styled.div`
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 20px 20px 10px 20px;
  border-radius: 10px;
`;

export const FormButton = styled.button`
  padding: ${(props) => (props.isSelected ? `5px 10px` : `15px 60px`)};
  background-color: ${(props) => (props.auth ? `#647295` : `#00A300`)};
  border: none;
  border-radius: 15px;
  color: white;
  margin: 20px auto;
  display: block;
  cursor: pointer;
  transition: 0.3s;
  font-size: 1em;
`;
export const EditFormButton = styled.button`
  padding: 10px 15px;
  background-color: ${(props) => (props.delete ? `red` : `green`)};
  color: white;
  text-align: center;
  border: none;
  border-radius: 15px;
  font-size: 1em;
  margin:30px 5px 10px 5px;
  
`;
export const Button = styled.p`
  position: relative;
  top: -31px;
  right: 0;
  background-color: white;
  padding: 10px 20px;
  border-radius: 15px 15px 0 0;
  cursor: pointer;
  border: none;
`;
export const ButtonTalent = styled.p`
  position: relative;
  top: -31px;
  right: 0;
  background-color: #cdd1f1;
  padding: 10px 40px;
  border-radius: 15px 15px 0 0;
  cursor: pointer;
  z-index: -1;
  border: none;
`;
