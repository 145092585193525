import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import SidebarTalents from "./SidebarTalents";
import HeaderOrg from "./HeaderOrg";

export const Layout = ({ children }) => (
  <>
    <HeaderOrg />
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Sidebar />
      <div
        style={{
          backgroundColor: "ffffff",
          width: "80vw",
          borderRadius: "20px",
          margin: "0 50px 10px 10px",
        }}
      >
        <div style={{ margin: "30px" }}>
          {children}

          {/* <Footer /> */}
          <Outlet />
        </div>

      </div>
    </div>
  </>
);

export const DefaultLayout = ({ children }) => (
  <>
    <div style={{ backgroundColor: "#d5ebf8", width: "100vw" }}>
      {children}
      <Outlet />
    </div>
  </>
);

export const TalentsLayout = ({ children }) => (
  <>
    <Header />
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <SidebarTalents />
      <div
        style={{
          backgroundColor: "ffffff",
          width: "80vw",
          borderRadius: "20px",
          margin: "0 50px 10px 10px",
        }}
      >
        <div style={{ margin: "30px" }}>
          {children}

          {/* <Footer /> */}
          <Outlet />
        </div>

      </div>
    </div>
  </>
);
// export const AuthWrapper = () => {
//   return isExpired(localStorage.getItem('token')
//     ? <Navigate to="/login" replace />
//     : <Outlet />
// };
