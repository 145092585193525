import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes } from "./routes";
// import { firebaseAnalytics } from "./firebaseConfig";
import { Router, BrowserRouter } from "react-router-dom";
const App = () => {
  // useEffect(() => {
  //   firebaseAnalytics.logEvent("homepage_visited");
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
     
        <Routes />
      </BrowserRouter>
    </div>
  );
};

export default App;
