import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MainButton } from "../../Layout/LayoutStyle";
import axios from "axios";
import { decode as base64_decode } from "base-64";
import { PROFILE_TALENT } from "../../api";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getColor } from "../../Styles/color";


const TalentsProfile = () => {
  const [response, setResponse] = useState("");
  const [matchedProjects, setMatchedProjects] = useState([]);
  const [skillsT, setSkills] = useState([]);
  const [imageT, setImage] = useState([]);

  const navigate = useNavigate();
  const token = localStorage.getItem("talentToken");

  useEffect(() => {
    axios
      .get(PROFILE_TALENT, {
        headers: {
          token: localStorage.getItem("talentToken"),
        },
      })
      .then((response) => {
        setResponse(response);
        setMatchedProjects(response.data.matchprojects);
        setSkills(response.data.skills);
        setImage(response.data.assests);
  
        // localStorage.setItem("talentName", response.data.name);
      });
  }, []);
 
  useEffect(() => {
    console.log("RESPINSE", response);
    if (
      response.data === "\n{'warning':'token_expiration'}" ||
      response.data === "\n"
    ) {
      navigate("/logInTalent");
    }
  });

  return (
    <>
      <h1>Talents's profile</h1>

      {token ? (
        <>
          <h2 style={{ paddingTop: 20, paddingBottom: 10 }}>Your projects</h2>
          {Array.isArray(matchedProjects)
            ? matchedProjects.map((project) => (
                // <Link to={`/projects/${project.id}`}>
                <div className="projects">
                  <p key={project?.id} className="linkname">
                    {base64_decode(project?.name)}
                  </p>
                </div>
                // </Link>
              ))
            : null}
          <>
            <h2 style={{ paddingTop: 20, paddingBottom: 10 }}>Your skills</h2>
            {skillsT.map((talent) => (
              <div className="projects">
                <button
                  style={{
                    backgroundImage: getColor(talent.skillname),
                    padding: "5px 15px",
                    borderRadius: 15,
                    border: "none",
                    fontSize: "1em",
                    marginRight: 10,
                    marginBottom:10,
                  }}
                  key={talent?.id}
                  className="linkname"
                >
                  {base64_decode(talent?.skillname)}
                </button>
              </div>
            ))}
          </>
          {/* {
            imageT.map((set)=>(
               <img src={set} alt="icons" />
            ))
          } */}
        
        </>
      ) : (
        <Navigate to="/logIn" />
      )}
    </>
  );
};
export default TalentsProfile;
