import React, { useState, useEffect } from "react";
import axios from "axios";
import { TALENTS_LIST, ORGANIZATION_PROFILE } from "../../api";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { decode as base64_decode } from "base-64";
import { encode as base64_encode } from "base-64";
import { handleColorType } from "../../Styles/Global";
import { TalentContainer, Container } from "../../Styles/Global";
import profileImage from "../../assets/natur.jpg";
import user from '../../assets/user.png';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getColor } from "../../Styles/color";
import Loader from "../../Layout/Loader";

const Profile = () => {
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();

  const [namEe, setName] = useState("all");
  const [response, setResponse] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [loading, isLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const fetched = await axios.get(TALENTS_LIST, {
        headers: {
          token: token,
        },
      });

      if (fetched.data === "\n{'warning':'token_expiration'}") {
        navigate("/logIn");
      } else console.log("ok");
      setResponse(fetched.data);

      isLoading(false);
    };
    getData();
  }, []);

  const filtered =
    namEe === "all"
      ? response
      : response.filter((talent) => {
          return talent.skills.some((skill) => skill.skillname === namEe);
        });

  const duplicateCheck = [];

  const SortTalents = (skillname) => {
    // console.log("SKILL", skillname);
    return filtered
      .filter((talent) => {
        return talent.skills.some(
          (skill) => skill.skillname === base64_encode(skillname)
        );
      })
      .map((talent) => {
        return (
          <TalentContainer>
            {talent.assets[0]
              ? talent.assets?.map((ass) => (
                  <img
                    src={`https://api.talents.wave2p0.com/assets/talent/${base64_decode(
                      ass.filename
                    )}`}
                    alt=""
                  />
                ))
              : <img src={user}/>}
            <div className="talentText">
              <p key={talent?.id} className="name">{base64_decode(talent.name)}</p>
              <p className="email">{base64_decode(talent.email)}</p>
              {talent?.skills.map((skill) => (
                <>
                  <button
                    style={{
                      backgroundColor: getColor(base64_decode(skill.skillname)),
                      padding: "5px 15px",
                      borderRadius: 15,
                      border: "none",
                      fontSize: "1em",
                      color: "white",
                      marginRight: 10,
                    }}
                    key={skill.id}
                  >
                    {base64_decode(skill?.skillname)}
                  </button>
                </>
              ))}
              {/* <p>
                Description of talent Description of talent Description of
                talent Description of talent
              </p> */}
              {/* <p className="more">more</p> */}
            </div>
          </TalentContainer>
        );
      });
  };
  const EmptySkills = () => {
    return (
      <>
        {filtered
          .filter((talent) => talent.skills.length === 0)
          .map((talent) => (
            <TalentContainer smaller>
              <img src={user} />
              <div className="talentText">
                <p key={talent?.id} className="name">{base64_decode(talent.name)}</p>
                <p className="email">{base64_decode(talent.email)}</p>
              </div>
              <p style={{ paddingLeft: 10 }}>No skills assigned yet.</p>
            </TalentContainer>
          ))}
      </>
    );
    // });
  };

  return (
    <>
      {token ? (
        <TalentListContainer>
          <h2 style={{ paddingBottom: 20 }}>Organization's profile</h2>
          <FormControl style={{ width: "40%" }}>
            <InputLabel id="demo-simple-select-label">Skill</InputLabel>
            <Select
              id="name"
              value={namEe}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Skill"
            >
              <MenuItem value="all">All</MenuItem>
              {response
                .map((talent) => {
                  talent.skills.map((skill) => {
                    if (duplicateCheck.includes(base64_decode(skill.skillname)))
                      return null;
                    duplicateCheck.push(base64_decode(skill.skillname));
                    return skill;
                  });
                })
                .filter((e) => e)}
              {duplicateCheck.map((skill) => (
                <MenuItem value={base64_encode(skill)}>{skill}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Link to="/createTalent">
            <button className="create">Create talent</button>
          </Link>
          <Loader loading={loading} />
          <Container>{EmptySkills()}</Container>
          {namEe === "all"
            ? duplicateCheck.map((skill) => {
                return (
                  <>
                    <TalentsSection color={skill}>
                      <div className="lighterBack">{skill}</div>
                    </TalentsSection>
                    <Container>{SortTalents(skill)}</Container>
                  </>
                );
              })
            : filtered.map((talent) => {
                return (
                  <Container>
                    <TalentContainer>
                      {talent.assets?.map((ass) => (
                        <img
                          src={`https://api.talents.wave2p0.com/assets/talent/${base64_decode(
                            ass.filename
                          )}`}
                          alt=""
                        />
                      ))}
                      <div className="talentText">
                        <p key={talent?.id} className="name">{base64_decode(talent.name)}</p>
                        <p>{base64_decode(talent.email)}</p>
                        {talent?.skills?.map((skill) => (
                          <>
                            <button
                              style={{
                                backgroundColor: getColor(
                                  base64_decode(skill.skillname)
                                ),
                                padding: "5px 15px",
                                borderRadius: 15,
                                border: "none",
                                fontSize: "1em",
                                marginRight: 10,
                                color: "white",
                              }}
                              key={skill.id}
                            >
                              {base64_decode(skill?.skillname)}
                            </button>
                          </>
                        ))}
                        {/* <p>
                          Description of talent Description of talent
                          Description of talent Description of talent
                        </p> */}
                        {/* <p className="more">more</p> */}
                      </div>
                    </TalentContainer>
                  </Container>
                );
              })}
        </TalentListContainer>
      ) : (
        <Navigate to="/logIn" />
        // <h1>FUCK</h1>
      )}
    </>
  );
};

const TalentsSection = styled.div`
  padding: 2px 100px;
  background-color: ${({ color }) => getColor(color)};
  text-align: center;
  margin: 30px 0;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  
  .lighterBack {
    background-color: white;
    width: 300px;
    margin: 0 auto;
    color: ${({ color }) => getColor(color)};
  }
`;
export const TalentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  .create {
    margin: 15px 0;
    padding: 10px 15px;
    border-radius: 15px;
    border: none;
    background-color: #8ec5fc;
    background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
    color: white;
    font-size: 1em;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
`;

export default Profile;
