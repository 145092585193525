import React, { useState, useContext } from "react";
// import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  MainContainer,
  Form,
  FieldMain,
  FormButton,
  Button,
  ButtonTalent,
} from "../../../Styles/FormStyles";
import talents_logo from "../../../assets/talents_logo.png";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import { LOGIN_URL } from "../../../api";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const LogIn = () => {
  const [user, setUser] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  // const { setToken } = useContext(UserContext);
  // const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const ChangeForm = () => {
    navigate("/logInTalent");
  };
  const onSubmit = (data) => {
    setUser(data);
    axios
      .get(LOGIN_URL, {
        headers: {
          email: base64_encode(data.email),
          password: base64_encode(data.password),
        },
      })
      .then((response) => {
        setResponse(response.data);
        localStorage.setItem("userToken", response.data.token);

        console.log("RESPONSE", response);
        if (response.data === "" || response.data === "\n") {
          alert("User does not exist in database!Please try again");
        } else {
          navigate("/profilePage");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <MainContainer main>
      <Form onSubmit={handleSubmit(onSubmit)} radius>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            top: -10,
            right: 0,
            zIndex: -3,
          }}
        >
          <Button>Organization</Button>
          <ButtonTalent onClick={ChangeForm}>Talent</ButtonTalent>
        </div>
        <div className="contentLogin">
          <img src={talents_logo} />
          <p style={{ textAlign: "center", fontSize: 30 }}>For organizations</p>
          {/* <h1>Talents</h1> */}
          <FieldMain>
            <label>Email address</label>
            <input
              placeholder="Email"
              type="email"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
          </FieldMain>
          {errors.email && <p className="text-error">Please check the Email</p>}
          <FieldMain>
            <label>Password</label>
            <input
              placeholder="Password"
              type="password"
              {...register("password", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
              })}
            />
          </FieldMain>
          {errors.password && (
            <p className="text-error">Please check the Password</p>
          )}
          <FieldMain>
            <label>
              <input
                type="checkbox"
                {...register("terms", { required: false })}
                style={{ marginRight: 10, marginTop: 5 }}
              />
              Keep me logged in
            </label>
          </FieldMain>
          <FormButton auth type="submit">
            Log In
          </FormButton>
          <p style={{ fontSize: 14, textAlign: "center", paddingBottom: 20 }}>
            Don’t have an account?{" "}
            <Link to="/register">
              <span style={{ color: "#647295", cursor: "pointer" }}>
                Create one here.
              </span>
            </Link>
          </p>
          <p style={{ fontSize: 14, textAlign: "center", paddingBottom: 20 }}>
            Forgot password?
            <span style={{ color: "#647295", cursor: "pointer" }}>
              {" "}
              Click here to reset.
            </span>
          </p>
        </div>
      </Form>
    </MainContainer>
  );
};
export default LogIn;
