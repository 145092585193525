import React, { useState } from "react";
// import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { MainContainer, Form, FieldMain, FormButton,Button,ButtonTalent } from "../../../Styles/FormStyles";
import talents_logo from "../../../assets/talents_logo.png";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import { REGISTER_URL } from "../../../api";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const SignUp = () => {
  const [user, setUser] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const ChangeForm = () => {
    navigate("/registerTalent");
  };

  const onSubmit = (data) => {
    setUser(data);
    // const headersS = {
    //   email:  base64_encode(data.email),
    //   password:  base64_encode(data.password),
    //   name:  base64_encode(data.name),

    // };

    axios
      .get(REGISTER_URL, {
        headers: {
          email: base64_encode(data.email),
          password: base64_encode(data.password),
          name: base64_encode(data.orgName),
        },
      })
      .then((response) => {
        setResponse(response.data);
      });
    if (response.length === 0) {
      alert("You already have an account");
    }
  };
  return (
    <MainContainer main>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            top: -40,
            right: 0,
            zIndex: -3,
          }}
        >
          <Button>Organization</Button>
          <ButtonTalent talents onClick={ChangeForm}>Talent</ButtonTalent>
        </div>
        <img src={talents_logo} />
        <h1>Get started with the free plan</h1>
        <FieldMain>
          <label>Organization’s name</label>
          <input
            placeholder="Organization’s name"
            type="text"
            {...register("orgName", { required: true, maxLength: 10 })}
          />
        </FieldMain>
        {errors.orgName && (
          <p className="text-error">Please check the Organization’s name</p>
        )}
        <FieldMain>
          <label>Email address</label>
          <input
            placeholder="Email"
            type="email"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </FieldMain>
        {errors.email && <p className="text-error">Please check the Email</p>}
        <FieldMain>
          <label>Password</label>
          <input
            placeholder="Password"
            type="password"
            {...register("password", {
              required: true,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
            })}
          />
        </FieldMain>
        {errors.password && (
          <p className="text-error">Please check the Password</p>
        )}
        <FieldMain>
          <label>
            <input
              type="checkbox"
              {...register("terms", { required: true, maxLength: 10 })}
              style={{ marginRight: 10, marginTop: 5 }}
            />
            I have read and I accept the terms of use
          </label>
        </FieldMain>
        {errors.terms && <p className="text-error">Please accept terms</p>}
        <FormButton auth type="submit">Sign Up</FormButton>
        <p style={{ fontSize: 14, textAlign: "center", paddingBottom: 20 }}>
          Already have an account?
          <Link to="/logIn">
            <span style={{ color: "#647295", cursor: "pointer" }}> Log in</span>
          </Link>
        </p>
      </Form>
    </MainContainer>
  );
};
export default SignUp;
