import { GET_PROFILE_DATA, UPDATE_PROFILE_DATA } from "./types";
import GetDataSevice from "../services/GetDataService";
import axios from "axios";
import { encode as base64_encode, decode as base64_decode } from "base-64";
import { NEW_ORGANIZATION_NAME } from "../api";
import authHeader from "../services/AuthHeader";
const token = localStorage.getItem("userToken");

export const getProfileData = () => async (dispatch) => {
  try {
    const res = await GetDataSevice.getAll();
    console.log("reponse",res)
    dispatch({
      type: GET_PROFILE_DATA,
      payload:  res.data,
    });
  } catch (err) {
    console.log(err,"FUCKK");
  }
};

export const updateProfileData = (user) => async (dispatch) => {
  try {
    const res = await GetDataSevice.updateData(base64_encode(user));
    console.log("Updated",user)
    // const config = {
    //   headers: {
    //     token:token,
    //     newname:base64_encode(user)
    //   },
    // };
    // console.log("CONFIG", config)
    // const { data } = await axios.get(
    //   NEW_ORGANIZATION_NAME,
    //   config
    // );
    console.log("DATA",res.data)
    dispatch({
      type: UPDATE_PROFILE_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err,"FUCKK");
  }
};


